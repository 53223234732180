import { FormControl, FormLabel, SimpleGrid } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { PasswordInput } from '@/components/UI/Inputs/Password/PasswordInput';

import { AppConfig } from '@/constants/app-config';
import { validatePassword } from '@/validators/password';

import { PasswordProps } from './PasswordProps';
import { FormControlError } from '../../FormControlError/FormControlError';
import { H2 } from '../../Heading';
import { RegisterForm } from '../../../models/forms/RegisterForm';

export const Password = (props: PasswordProps<RegisterForm>) => {
    const { formInstance } = props;
    const { formState, register, watch } = formInstance;
    const passwordWatcher = watch('password');
    const PASSWORD_MIN_LENGTH = AppConfig.PasswordMinLength;

    const passwordControl = register('password', {
        required: {
            value: true,
            message: 'field-is-required'
        },
        validate: (value) => validatePassword(value)
    });
    const confirmPasswordControl = register('confirmPassword', {
        required: {
            value: true,
            message: 'field-is-required'
        },
        validate: (value) => {
            if (value !== passwordWatcher) {
                return 'passwords-does-not-match';
            }
            return undefined;
        }
    });

    return (
        <fieldset>
            <H2 size="h3">
                <FormattedMessage id="create-password" />
            </H2>
            <SimpleGrid
                columns={{
                    base: 1,
                    sm: 2
                }}
                columnGap={4}
            >
                <FormControl isInvalid={!!formState.errors[passwordControl.name]} isRequired>
                    <FormLabel>
                        <FormattedMessage id="password" />
                    </FormLabel>
                    <FormControlError
                        formState={formState}
                        control={passwordControl}
                        values={{
                            minLength: PASSWORD_MIN_LENGTH
                        }}
                    >
                        <PasswordInput inputProps={passwordControl} />
                    </FormControlError>
                </FormControl>
                <FormControl isInvalid={!!formState.errors[confirmPasswordControl.name]} isRequired>
                    <FormLabel>
                        <FormattedMessage id="confirm-password" />
                    </FormLabel>
                    <FormControlError formState={formState} control={confirmPasswordControl}>
                        <PasswordInput inputProps={confirmPasswordControl} />
                    </FormControlError>
                </FormControl>
            </SimpleGrid>
        </fieldset>
    );
};
