import { Flex, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { DotSeparator } from './DotSeparator';

export const IntroHeader = () => {
    const items = [
        'page.register.lower-prices',
        'page.register.discounts-and-promotions',
        'sign-up-and-buy'
    ];

    return (
        <Flex
            columnGap={4}
            color="grey.500"
            fontWeight="bold"
            justifyContent={{
                sm: 'center'
            }}
            flexWrap="wrap"
            mb={4}
        >
            {items.map((item, index) => (
                <Flex key={index} alignItems="center">
                    <DotSeparator
                        display={{
                            base: 'block',
                            md: index === 0 ? 'none' : 'block'
                        }}
                    />
                    <Text>
                        <FormattedMessage id={item} />
                    </Text>
                </Flex>
            ))}
        </Flex>
    );
};
