import { AppConfig } from '@/constants/app-config';
import { ValidationKey } from '@/constants/validation-key';
import { ValidationReturn } from '@/validators/countryInstanceValidators';

export const validatePassword = (
    password: string,
    minLength = AppConfig.PasswordMinLength,
    maxLength = AppConfig.PasswordMaxLength
): ValidationReturn => {
    if (password.length < minLength) {
        return ValidationKey.PasswordTooShort;
    }
    if (password.length > maxLength) {
        return ValidationKey.PasswordTooLong;
    }
    return undefined;
};

export const validatePasswordsMatching = (
    password: string,
    anotherPassword: string
): ValidationReturn => {
    return password !== anotherPassword ? ValidationKey.PasswordsDoesNotMatch : undefined;
};
