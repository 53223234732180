import { Box, Container } from '@chakra-ui/react';
import { deleteCookie } from 'cookies-next';
import { GetServerSidePropsContext, NextPage } from 'next';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { withCommonServerSideProps } from '@/lib/withCommonServerSideProps';

import { PageTitle } from '@/components/PageTitle/PageTitle';
import Seo from '@/components/Seo';

import { CookieKeys } from '@/constants/cookie-keys';

import { RegisterComplete } from '../components/Register';
import RegisterUserForm from '../components/Register/RegisterUserForm';

const Register: NextPage = () => {
    const intl = useIntl();
    const [registerSuccessful, setRegisterSuccessful] = useState(false);

    const onRegisterSubmit = () => {
        setRegisterSuccessful(() => true);
    };

    return (
        <Container maxW="5xl">
            <Seo templateTitle={intl.formatMessage({ id: 'page.register.title' })} />
            <Box py={16}>
                <PageTitle title="page.register.title" />
                {registerSuccessful ? (
                    <RegisterComplete />
                ) : (
                    <RegisterUserForm onRegisterSubmit={onRegisterSubmit} />
                )}
            </Box>
        </Container>
    );
};

export const getServerSideProps = withCommonServerSideProps(
    async (context: GetServerSidePropsContext) => {
        const { req, res } = context;
        deleteCookie(CookieKeys.showLoginDisabledPopup, { req, res });
        return {
            props: {}
        };
    }
);

export default Register;
