import {
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftAddon,
    SimpleGrid
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormControlError } from '@/components/FormControlError/FormControlError';
import { H2 } from '@/components/Heading';

import { ValidationKey } from '@/constants/validation-key';
import { RegistrationFormError } from '@/models/api';
import { RegisterForm } from '@/models/forms/RegisterForm';
import { AppService } from '@/services';
import { validateName } from '@/validators/name';
import { validateSurname } from '@/validators/surname';

import { PersonalDataProps } from './PersonalDataProps';

interface PersonalDataExtProps<T extends RegisterForm> extends PersonalDataProps<T> {
    errorsField?: RegistrationFormError[];
}

export const PersonalData = (props: PersonalDataExtProps<RegisterForm>) => {
    const { formInstance, errorsField } = props;
    const { formState, register, watch } = formInstance;
    const intl = useIntl();
    const phonePrefix = AppService.getPhonePrefix();
    const instanceCountryValidators = AppService.getInstanceCountryValidators();
    const emailWatcher = watch('email');

    const firstNameControl = register('name', {
        required: {
            value: true,
            message: ValidationKey.FieldIsRequired
        },
        validate: (name) => validateName(name),
        maxLength: {
            value: 30,
            message: intl.formatMessage({ id: 'maxLength' }, { num: '30' })
        }
    });
    const lastNameControl = register('surname', {
        required: {
            value: true,
            message: ValidationKey.FieldIsRequired
        },
        validate: (name) => validateSurname(name),
        maxLength: {
            value: 30,
            message: intl.formatMessage({ id: 'maxLength' }, { num: '30' })
        }
    });
    const phoneNumberControl = register('phone', {
        validate: (phone) => {
            if (!phone) {
                return undefined;
            }
            return instanceCountryValidators.validatePhoneNumber(phone);
        }
    });

    const emailControl = register('email', {
        required: {
            value: true,
            message: ValidationKey.FieldIsRequired
        },
        pattern: {
            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: ValidationKey.PleaseEnterValidEmail
        }
    });

    const confirmEmailControl = register('confirmEmail', {
        required: {
            value: true,
            message: ValidationKey.FieldIsRequired
        },
        validate: (value) => {
            if (value !== emailWatcher) {
                return ValidationKey.EmailsNotMatch;
            }
            return undefined;
        }
    });

    return (
        <fieldset>
            <H2 size="h3">
                <FormattedMessage id="fill-up-personal-data" />
            </H2>
            <SimpleGrid
                columns={{
                    base: 1,
                    sm: 2
                }}
                columnGap={4}
            >
                <FormControl
                    isInvalid={
                        !!formState.errors[firstNameControl.name] ||
                        errorsField?.some((field) => field.field === 'name')
                    }
                    isRequired
                >
                    <FormLabel>
                        <FormattedMessage id="first-name" />
                    </FormLabel>
                    <FormControlError formState={formState} control={firstNameControl}>
                        <Input {...firstNameControl} autoComplete="name" />
                    </FormControlError>
                </FormControl>
                <FormControl
                    isInvalid={
                        !!formState.errors[lastNameControl.name] ||
                        errorsField?.some((field) => field.field === 'surname')
                    }
                    isRequired
                >
                    <FormLabel>
                        <FormattedMessage id="last-name" />
                    </FormLabel>
                    <FormControlError formState={formState} control={lastNameControl}>
                        <Input {...lastNameControl} />
                    </FormControlError>
                </FormControl>
            </SimpleGrid>
            <SimpleGrid
                columns={{
                    base: 1,
                    sm: 2
                }}
                columnGap={4}
            >
                <FormControl
                    isInvalid={
                        !!formState.errors[emailControl.name] ||
                        errorsField?.some((field) => field.field === 'email')
                    }
                    isRequired
                >
                    <FormLabel>
                        <FormattedMessage id="e-mail" />
                    </FormLabel>
                    <FormControlError formState={formState} control={emailControl}>
                        <Input type="email" {...emailControl} autoComplete="email" />
                    </FormControlError>
                </FormControl>
                <FormControl
                    isInvalid={
                        !!formState.errors[confirmEmailControl.name] ||
                        errorsField?.some((field) => field.field === 'email')
                    }
                    isRequired
                >
                    <FormLabel>
                        <FormattedMessage id="confirm-e-mail" />
                    </FormLabel>
                    <FormControlError formState={formState} control={confirmEmailControl}>
                        <Input type="email" {...confirmEmailControl} />
                    </FormControlError>
                </FormControl>
                <FormControl
                    isInvalid={
                        !!formState.errors[phoneNumberControl.name] ||
                        errorsField?.some((field) => field.field === 'phone')
                    }
                >
                    <FormLabel>
                        <FormattedMessage id="phone" />
                    </FormLabel>
                    <FormControlError formState={formState} control={phoneNumberControl}>
                        <InputGroup>
                            <InputLeftAddon>{phonePrefix}</InputLeftAddon>
                            <Input type="tel" {...phoneNumberControl} />
                        </InputGroup>
                    </FormControlError>
                </FormControl>
            </SimpleGrid>
        </fieldset>
    );
};
