import { Box, Checkbox, FormControl, FormLabel } from '@chakra-ui/react';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormControlError } from '@/components/FormControlError/FormControlError';
import { H2 } from '@/components/Heading';
import NavLink from '@/components/UI/Links/NavLink';

import { RegisterForm } from '@/models/forms/RegisterForm';
import { AppService } from '@/services';

import { RegisterAgreementsProps } from './RegisterAgreementsProps';

export const RegisterAgreements = (props: RegisterAgreementsProps<RegisterForm>) => {
    const { formInstance } = props;
    const { formState, register, setValue, getValues } = formInstance;
    const [checkedItems, setCheckedItems] = useState([false, false, false, false, false]);
    const contactEmail = AppService.getContactEmailAddress();
    const clubPrivacyPolicyFileLink = AppService.getClubPrivacyPolicyFileLink();
    const informationObligationFileLink = AppService.getInformationObligationFileLink();
    const clubRegulationsFileLink = AppService.getClubRegulationsFileLink();
    const dataClubRulesFormattedMessage = useCallback(
        (messages: ReactNode[]) => {
            return (
                <NavLink variant="underlineBrand" href={clubRegulationsFileLink} isExternal>
                    {messages}
                </NavLink>
            );
        },
        [clubRegulationsFileLink]
    );
    const dataInformationObligationFormattedMessage = useCallback(
        (messages: ReactNode[]) => {
            return (
                <NavLink variant="underlineBrand" href={informationObligationFileLink} isExternal>
                    {messages}
                </NavLink>
            );
        },
        [informationObligationFileLink]
    );
    const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;

        setCheckedItems(Array(5).fill(isChecked));

        const requiredCheckboxes = ['adult', 'privacyPolicyAccepted', 'rodoAccepted'];
        const otherCheckboxes = ['sponsorAccepted', 'rodoMarketingAccepted'];

        const values = getValues();

        if (isChecked) {
            [...requiredCheckboxes, ...otherCheckboxes].forEach((name) =>
                setValue(name as keyof RegisterForm, true)
            );
        } else {
            requiredCheckboxes.forEach((name) =>
                setValue(name as keyof RegisterForm, values[name as keyof RegisterForm])
            );
            otherCheckboxes.forEach((name) => setValue(name as keyof RegisterForm, false));
        }
    };
    const handleChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedCheckedItems = [...checkedItems];

        updatedCheckedItems[index] = e.target.checked;
        setCheckedItems(updatedCheckedItems);
    };
    const privacyPolicyFormattedMessage = useCallback(
        (messages: ReactNode[]) => {
            return (
                <NavLink href={clubPrivacyPolicyFileLink} isExternal variant="underlineBrand">
                    {messages}
                </NavLink>
            );
        },
        [clubPrivacyPolicyFileLink]
    );
    const dataStrong = useCallback(
        (messages: ReactNode[]) => (
            <Box as="strong" fontWeight="bold">
                {messages}
            </Box>
        ),
        []
    );

    const formControls = useMemo(() => {
        return [
            { name: 'adult', labelId: 'i-am-adult', required: true, values: {} },
            {
                name: 'privacyPolicyAccepted',
                labelId: 'agreement-privacy-policy-text',
                required: true,
                values: {
                    strong: dataStrong,
                    'data-club-rules': dataClubRulesFormattedMessage,
                    'data-information-obligation': dataInformationObligationFormattedMessage,
                    'data-privacy-policy-link': privacyPolicyFormattedMessage
                }
            },
            {
                name: 'rodoAccepted',
                labelId: 'agreement-rodo-text',
                required: true,
                values: {
                    strong: dataStrong,
                    'data-privacy-policy-link': privacyPolicyFormattedMessage
                }
            },
            {
                name: 'sponsorAccepted',
                labelId: 'page.register.agreement-sponsor',
                required: false,
                values: {
                    strong: dataStrong
                }
            },
            {
                name: 'rodoMarketingAccepted',
                labelId: 'page.register.agreement-marketing',
                required: false,
                values: {
                    strong: dataStrong,
                    'data-contact-email': () => {
                        return (
                            <NavLink
                                variant="underlineBrand"
                                href={`mailto:${contactEmail}`}
                                isExternal
                                locale={false}
                            >
                                {contactEmail}
                            </NavLink>
                        );
                    },
                    'data-privacy-policy-link': privacyPolicyFormattedMessage
                }
            }
        ];
    }, [
        dataStrong,
        contactEmail,
        dataClubRulesFormattedMessage,
        dataInformationObligationFormattedMessage,
        privacyPolicyFormattedMessage
    ]);

    return (
        <Box as="fieldset" whiteSpace="pre-line" marginBottom={4} textAlign="left">
            <H2 size="h3">
                <FormattedMessage id="agreements" />
            </H2>
            <FormControl display="flex" alignItems="flex-start" columnGap={2}>
                <Checkbox
                    mt={1}
                    isChecked={checkedItems.every(Boolean)}
                    isIndeterminate={checkedItems.some(Boolean) && !checkedItems.every(Boolean)}
                    onChange={handleSelectAllChange}
                />
                <Box>
                    <FormLabel color="black" fontWeight="normal">
                        <FormattedMessage id="page.register.check-all" />
                    </FormLabel>
                </Box>
            </FormControl>

            {formControls.map(({ name, labelId, required, values }, index) => {
                const isError = !!formState.errors[name as keyof RegisterForm];
                const isChecked = checkedItems[index];

                return (
                    <FormControl
                        key={name}
                        isInvalid={isError}
                        isRequired={required}
                        display="flex"
                        alignItems="flex-start"
                        columnGap={2}
                    >
                        <Checkbox
                            mt={1}
                            {...register(name as keyof RegisterForm, {
                                required: { value: required, message: 'field-is-required' }
                            })}
                            isChecked={isChecked}
                            onChange={handleChange(index)}
                        />
                        <Box>
                            <FormLabel color="black" fontWeight="normal">
                                <FormattedMessage id={labelId} values={values} />
                            </FormLabel>
                            <FormControlError
                                formState={formState}
                                control={register(name as keyof RegisterForm)}
                            ></FormControlError>
                        </Box>
                    </FormControl>
                );
            })}
        </Box>
    );
};
