import { ValidationKey } from '@/constants/validation-key';

export function maxSameCharacters(value: string, maxChars = 2) {
    const pattern = `(.)\\1{${maxChars},}`;
    const repeatedCharactersPattern = new RegExp(pattern, 'gi');

    return repeatedCharactersPattern.test(value) ? ValidationKey.InvalidFormat : undefined;
}

export function validateMinLength(value: string, minLength: number) {
    return value.length >= minLength;
}

export function validatePattern(value: string, pattern: RegExp) {
    return pattern.test(value);
}
