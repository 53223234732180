import { AlertDescription, AlertIcon, AlertTitle, Box, Container } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { Alert } from '@/components/UI';

import NavLink from '../../UI/Links/NavLink';
import RoutePath from '../../../constants/route-path';

export const RegisterComplete = () => {
    return (
        <Container maxW="xl">
            <Alert
                status="success"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                minHeight="200px"
                padding={6}
                bg="white"
            >
                <AlertIcon boxSize="40px" mr={0} color="brand.main" />

                <AlertTitle mt={4} mb={1} fontSize="xl" color="black">
                    <FormattedMessage id="registration-completed" />
                </AlertTitle>
                <AlertDescription maxWidth="sm" mb={6} fontSize="sm">
                    <FormattedMessage id="registration-completed-message" />
                </AlertDescription>
                <Box>
                    <NavLink
                        skipRouter={true}
                        href={RoutePath.Home}
                        variant="underlineBrand"
                        passHref
                    >
                        <FormattedMessage id="go-to-homepage" />
                    </NavLink>
                </Box>
            </Alert>
        </Container>
    );
};
