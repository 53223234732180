import { ValidationKey } from '@/constants/validation-key';
import { maxSameCharacters } from '@/validators/common';
import { ValidationReturn } from '@/validators/countryInstanceValidators';

export function validateName(name: string, minLength = 2): ValidationReturn {
    if (name.length < minLength) return ValidationKey.FieldToShort;
    name = name || '';

    name = name.trim();

    const repeatedCharactersValidation = maxSameCharacters(name, minLength);
    const pattern = new RegExp(/^[0-9a-zA-Z\u00C0-\u017Fẞß]{2}[a-zA-Z\s\-\u00C0-\u02AF-]*$/, 'g');
    const isValid = pattern.test(name.toLowerCase()) && !repeatedCharactersValidation;

    return !isValid ? ValidationKey.InvalidFormat : undefined;
}
