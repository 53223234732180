import { Box, BoxProps, HeadingProps } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { H1 } from '@/components/Heading';

type PageTitleProps = {
    title: string;
    boxProps?: BoxProps;
    headingProps?: HeadingProps;
    postTitleContent?: ReactElement | null;
};

export const PageTitle = (props: PageTitleProps) => {
    const { title, headingProps, boxProps, postTitleContent } = props;
    return (
        <Box
            textAlign="center"
            mb={{
                base: 6,
                lg: 8
            }}
            {...boxProps}
        >
            <H1 mb={0} {...headingProps}>
                <FormattedMessage id={title} defaultMessage={title} />
            </H1>
            {postTitleContent}
        </Box>
    );
};
