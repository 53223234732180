import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
    AlertDescription,
    Box,
    Flex,
    FormControl,
    FormLabel,
    Switch,
    Text,
    Tooltip,
    useDisclosure
} from '@chakra-ui/react';
import { isAxiosError } from 'axios';
import { useRouter } from 'next/router';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { debounce } from 'underscore';

import MemberNumberInput, { Alert, AlertIcon } from '@/components/UI';

import { RegistrationSponsorInfo } from '@/models/api';
import { RegisterForm } from '@/models/forms/RegisterForm';
import { AppService, RegistrationService } from '@/services';
import { onlyNumberValidation } from '@/validators';

import { IntroductoryPersonProps } from './IntroductoryPersonProps';
import { FormControlError } from '../../FormControlError/FormControlError';

type QueryParams = {
    sponsorNo?: string;
};

export const IntroductoryPerson = (props: IntroductoryPersonProps<RegisterForm>) => {
    const router = useRouter();
    const query = router.query as QueryParams;
    const intl = useIntl();
    const { formInstance, internationalSponsor, introductoryPersonCallback } = props;
    const { clearErrors, formState, register, setValue, setError } = formInstance;
    const [isReferrerChecked, setIsReferrerChecked] = useState(false);
    const [sponsorData, setSponsorData] = useState<RegistrationSponsorInfo>();
    const { isOpen, onOpen, onToggle, onClose } = useDisclosure();
    const [sponsorFullName, setSponsorFullName] = useState<string>('');
    const [sponsorNo, setSponsorNo] = useState(query.sponsorNo);
    const [sponsorFound, setSponsorFound] = useState<boolean | null>(null);
    const obj = useMemo(
        () => ({
            intl,
            setError,
            setValue
        }),
        [intl, setError, setValue]
    );

    const referrerNumberControl = register('sponsorNo', {
        validate: (value = '') => {
            if (!value) {
                return undefined;
            }
            return onlyNumberValidation(value);
        },
        required: {
            value: !!sponsorNo,
            message: 'field-is-required'
        }
    });
    const removeCountryPrefix = useCallback((sponsorNumber: string): string => {
        sponsorNumber = sponsorNumber || '';
        const delimiterPosition = sponsorNumber?.indexOf('-');
        if (delimiterPosition === -1) {
            return sponsorNumber;
        }
        return sponsorNumber.slice(delimiterPosition + 1);
    }, []);

    const referrerNumberInputHandler = useCallback(
        (event: SyntheticEvent) => {
            setSponsorFullName(() => '');
            setSponsorFound(null);
            const instanceCountryCode = AppService.getAppCountryCode();
            const memberNumber = (event.target as HTMLInputElement).value;

            if (!memberNumber || !!formState.errors[referrerNumberControl.name]) {
                return;
            }

            RegistrationService.getSponsorInfo(`${instanceCountryCode}-${memberNumber}`)
                .then((user) => {
                    setSponsorData(() => user);
                    setSponsorFound(true);
                    clearErrors('sponsorNo');
                })
                .catch((error) => {
                    if (isAxiosError(error) && error?.response?.status === 404) {
                        obj.setError(referrerNumberControl.name, {
                            message: 'validation.sponsorNo.invalid'
                        });
                        setSponsorFound(false);
                    }
                })
                .finally(() => {
                    clearErrors('sponsorNo');
                });
        },
        [formState.errors, referrerNumberControl.name, obj, clearErrors]
    );

    const updateSponsorFullName = useCallback((name = '', surname = ''): void => {
        const newName = `${name} ${surname}`.trim();

        setSponsorFullName(() => newName);
    }, []);

    const onReferrerNumberInput = useMemo(
        () => debounce(referrerNumberInputHandler, 1000),
        [referrerNumberInputHandler]
    );
    useEffect(() => {
        if (internationalSponsor && isReferrerChecked) {
            setIsReferrerChecked(false);
        }
    }, [internationalSponsor, isReferrerChecked]);
    useEffect(() => {
        introductoryPersonCallback(!!isReferrerChecked);

        if (!isReferrerChecked) {
            clearErrors(referrerNumberControl.name);
            setSponsorFound(null);
            setSponsorData(() => undefined);
            obj.setValue(referrerNumberControl.name, '');
        }
    }, [
        isReferrerChecked,
        referrerNumberControl.name,
        introductoryPersonCallback,
        obj,
        clearErrors
    ]);

    useEffect(() => {
        const { sponsorNo } = query;
        if (!sponsorNo) {
            return;
        }
        RegistrationService.getSponsorInfo(sponsorNo)
            .then((info) => {
                setIsReferrerChecked(() => true);
                setSponsorData(() => info);
                setSponsorFound(true);
            })
            .catch((error) => {
                if (isAxiosError(error) && error?.response?.status === 404) {
                    obj.setError(referrerNumberControl.name, {
                        message: 'sponsorNo.invalid'
                    });
                    setSponsorData(() => {
                        return {
                            representativeNo: sponsorNo,
                            surname: '',
                            name: '',
                            city: ''
                        };
                    });
                    setSponsorFound(false);
                }
            })
            .finally(() => {
                clearErrors('sponsorNo');
            });
    }, [clearErrors, query, referrerNumberControl.name, obj]);

    useEffect(() => {
        if (!sponsorData || !isReferrerChecked) {
            updateSponsorFullName();
            return;
        }
        updateSponsorFullName(sponsorData.name, sponsorData.surname);
        obj.setValue(referrerNumberControl.name, removeCountryPrefix(sponsorData.representativeNo));
    }, [
        isReferrerChecked,
        sponsorData,
        referrerNumberControl.name,
        updateSponsorFullName,
        removeCountryPrefix,
        obj
    ]);

    useEffect(() => {
        if (!query.sponsorNo) {
            return;
        }
        setSponsorNo(() => query.sponsorNo);
        setIsReferrerChecked(() => true);
    }, [query.sponsorNo]);

    return (
        <fieldset>
            <FormControl isDisabled={internationalSponsor}>
                <Flex alignItems="center">
                    <FormLabel display="flex" alignItems="center">
                        <FormattedMessage id="do-you-have-introductory-person" />
                    </FormLabel>
                    <Tooltip
                        bg="brand.darker"
                        borderColor="grey.900"
                        hasArrow
                        isOpen={isOpen}
                        label={intl.formatMessage({
                            id: internationalSponsor
                                ? 'introductory-person-tooltip.disabled'
                                : 'introductory-person-tooltip'
                        })}
                    >
                        <InfoOutlineIcon
                            color="brand.darker"
                            mb={2}
                            onClick={onToggle}
                            onMouseEnter={onOpen}
                            onMouseLeave={onClose}
                        />
                    </Tooltip>
                </Flex>
                <Box>
                    <Text as="span" opacity={internationalSponsor ? 0.4 : 1}>
                        <FormattedMessage id="no" />
                    </Text>
                    <Switch
                        isReadOnly={sponsorNo !== undefined}
                        mx={2}
                        isChecked={isReferrerChecked}
                        onChange={() => {
                            if (!internationalSponsor) {
                                setIsReferrerChecked((prevState) => !prevState);
                            }
                        }}
                    />
                    <Text as="span" opacity={internationalSponsor ? 0.4 : 1}>
                        <FormattedMessage id="yes" />
                    </Text>
                </Box>
            </FormControl>
            {isReferrerChecked && (
                <>
                    <FormControl
                        isInvalid={!!formState.errors[referrerNumberControl.name]}
                        isRequired={isReferrerChecked}
                        isReadOnly={sponsorNo !== undefined}
                    >
                        <FormLabel>
                            <FormattedMessage id="referrer-number" />
                        </FormLabel>
                        <FormControlError formState={formState} control={referrerNumberControl}>
                            <MemberNumberInput
                                formControlRef={referrerNumberControl}
                                inputProps={{
                                    onInput: onReferrerNumberInput
                                }}
                            />
                        </FormControlError>
                    </FormControl>
                    {sponsorFound !== null && (
                        <Flex columnGap={2}>
                            {sponsorFullName ? (
                                <>
                                    <Text fontWeight="semibold" flexShrink={0}>
                                        <FormattedMessage id="page.profile.tabs.name-and-surname" />
                                    </Text>
                                    <Text wordBreak="break-word">{sponsorFullName}</Text>
                                </>
                            ) : (
                                <Alert
                                    status={sponsorFound ? 'info' : 'warning'}
                                    bg={sponsorFound ? 'grey.100' : 'accent.200'}
                                >
                                    <AlertIcon color={sponsorFound ? 'black' : 'danger.500'} />
                                    <AlertDescription color={sponsorFound ? 'black' : 'danger.500'}>
                                        <Text>
                                            <FormattedMessage
                                                id={
                                                    sponsorFound
                                                        ? 'sponsor-assigned'
                                                        : 'representativeNo.invalid'
                                                }
                                            />
                                        </Text>
                                    </AlertDescription>
                                </Alert>
                            )}
                        </Flex>
                    )}
                </>
            )}
        </fieldset>
    );
};
