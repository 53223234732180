import { Box, BoxProps, forwardRef } from '@chakra-ui/react';

export const DotSeparator = forwardRef<BoxProps, 'div'>((props, ref) => {
    const size = '.375rem';
    return (
        <Box
            as="span"
            display="inline-block"
            flexShrink="0"
            width={size}
            height={size}
            backgroundColor="currentcolor"
            marginRight="1rem"
            aria-hidden="true"
            ref={ref}
            {...props}
        />
    );
});
