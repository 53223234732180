import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
    Button,
    Input,
    InputGroup,
    InputProps,
    InputRightElement,
    VisuallyHidden
} from '@chakra-ui/react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

type PasswordInputProps = {
    inputProps?: InputProps;
};

export const PasswordInput = (props: PasswordInputProps) => {
    const { inputProps } = props;
    const [passwordVisible, setPasswordVisible] = useState(false);

    const passwordVisibilityClickHandler = () => {
        setPasswordVisible((prevState) => !prevState);
    };

    return (
        <InputGroup>
            <Input
                type={passwordVisible ? 'text' : 'password'}
                autoComplete="new-password"
                {...inputProps}
            />
            <InputRightElement width="4.5rem">
                <Button
                    h="1.75rem"
                    size="sm"
                    onClick={passwordVisibilityClickHandler}
                    variant="ghost"
                    colorScheme="grey"
                >
                    <VisuallyHidden>
                        <FormattedMessage
                            id={passwordVisible ? 'hide-password' : 'show-password'}
                        />
                    </VisuallyHidden>
                    {passwordVisible ? <ViewOffIcon /> : <ViewIcon />}
                </Button>
            </InputRightElement>
        </InputGroup>
    );
};
