import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    forwardRef,
    List,
    ListItem
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { useValidation } from '@/hooks/useValidation';

import { RegisterFailedProps } from './RegisterFailedProps';

export const RegisterFailed = forwardRef<RegisterFailedProps, 'div'>(
    (props: RegisterFailedProps, ref) => {
        const { errors } = props;
        const formErrors = errors.filter((error) => error.location === 'FORM');
        const fieldErrors = errors.filter((error) => error.location === 'FIELD');
        const errorsList = formErrors.length ? formErrors : fieldErrors;
        const { getValidationKey } = useValidation();

        return (
            <Alert ref={ref} status="error" variant="subtle" flexWrap="wrap" my={4}>
                <AlertIcon />
                <AlertTitle mb={1} fontSize="lg">
                    <FormattedMessage id="registration-failed" />
                </AlertTitle>
                <AlertDescription flexBasis="100%">
                    <List>
                        {errorsList.map((error) => (
                            <ListItem key={error.code}>
                                <FormattedMessage id={getValidationKey(error.code)} />
                            </ListItem>
                        ))}
                    </List>
                </AlertDescription>
            </Alert>
        );
    }
);
RegisterFailed.displayName = RegisterFailed.name;
